import React from 'react';
import { shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import classNames from 'classnames';

import css from './SectionRulesMaybe.module.css';

const SectionRulesMaybe = props => {
  const { className, rootClassName, publicData = {}, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  const RULES_OPTIONS = [
    {
      key: 'rules_option_no_smoking',
      label: intl.formatMessage({ id: 'EditListingPoliciesForm.rulesOptionNoSmoking'}),
    },
    {
      key: 'rules_option_no_parties',
      label: intl.formatMessage({ id: 'EditListingPoliciesForm.rulesOptionNoParties'}),
    },
    {
      key: 'rules_option_no_events',
      label: intl.formatMessage({ id: 'EditListingPoliciesForm.rulesOptionNoEvents'}),
    },
    {
      key: 'rules_option_no_loud_noise',
      label: intl.formatMessage({ id: 'EditListingPoliciesForm.rulesOptionNoLoudNoise'}),
    },
    {
      key: 'rules_option_no_drugs',
      label: intl.formatMessage({ id: 'EditListingPoliciesForm.rulesOptionNoDrugs'}),
    },
    {
      key: 'rules_option_self_check_in_out',
      label: intl.formatMessage({ id: 'EditListingPoliciesForm.rulesOptionSelfCheckInOut'}),
    },
    {
      key: 'rules_option_hand_off_key',
      label: intl.formatMessage({ id: 'EditListingPoliciesForm.rulesOptionHandOffKey'}),
    }
  ];

  const moveInOutSection = publicData.checkInTime || publicData.checkOutTime ? (
    <div className={css.moveInOutRow}>
      { publicData.checkInTime ? (
        <div className={css.moveInCell}>
          <FormattedMessage id="ListingPage.checkInLabel" />
          <div className={css.moveInTime}>{publicData.checkInTime}</div>
        </div>
      ) : null }
      { publicData.checkOutTime ? (
        <div className={css.moveOutCell}>
          <FormattedMessage id="ListingPage.checkOutLabel" />
          <div className={css.moveOutTime}>{publicData.checkOutTime}</div>
        </div>
      ) : null }
    </div>
  ) : null;

  const { numberOfChildren, numberOfInfants, numberOfTenants } = publicData;
  const tenantsAllowedSection = numberOfChildren || numberOfInfants || numberOfTenants ? (
    <div className={css.tenantsAllowedRow}>
      { numberOfTenants ? (
        <div className={css.tenantsCell}>
          <FormattedMessage id="ListingPage.tenantGuestAllowed" />
          <div className={css.numberOfTenants}>{numberOfTenants}</div>
        </div>
      ) : null }
      { numberOfChildren ? (
        <div className={css.childrenCell}>
          <FormattedMessage id="ListingPage.childrenAllowed" />
          <div className={css.numberOfChildren}>{numberOfChildren}</div>
        </div>
      ) : null }
      { numberOfInfants ? (
        <div className={css.infantsCell}>
          <FormattedMessage id="ListingPage.infantsAllowed" />
          <div className={css.numberOfInfants}>{numberOfInfants}</div>
        </div>
      ) : null }
    </div>
  ) : null;

  const {
    catsAllowed: catsAllowedOption,
    dogsAllowed: dogsAllowedOption,
    noPetsAllowed: noPetsAllowedOption
  } = publicData;

  const catsAllowed = Array.isArray( catsAllowedOption ) &&
    catsAllowedOption.length === 1 && catsAllowedOption[ 0 ] === 'yes' ;
  const dogsAllowed = Array.isArray( dogsAllowedOption ) &&
    dogsAllowedOption.length === 1 && dogsAllowedOption[ 0 ] === 'yes' ;
  const noPetsAllowed = Array.isArray( noPetsAllowedOption ) &&
    noPetsAllowedOption.length === 1 && noPetsAllowedOption[ 0 ] === 'yes' ;

  const petPolicyOptions = [];
  const petPolicySelectedOptions = [];
  if( catsAllowed ){
    petPolicySelectedOptions.push('catsAllowed');
    petPolicyOptions.push({ key: 'catsAllowed', label: 'Cats allowed', isSelected: true });
  }
  if( dogsAllowed ){
    petPolicySelectedOptions.push('dogsAllowed');
    petPolicyOptions.push({ key: 'dogsAllowed', label: 'Dogs allowed', isSelected: true });
  }
  if( noPetsAllowed ){
    petPolicySelectedOptions.push('noPetsAllowed');
    petPolicyOptions.push({ key: 'noPetsAllowed', label: 'No pets allowed', isSelected: true });
  }

  const petPolicySection = catsAllowed || dogsAllowed || noPetsAllowed ? (
    <>
      <h2 className={css.petPolicyTitle}>
        <FormattedMessage id="ListingPage.petPolicyTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.petPolicyOptions"
        options={petPolicyOptions}
        selectedOptions={petPolicySelectedOptions}
        twoColumns={false}
        hideUnselected={true}
      />
    </>
  ) : null;

  return publicData && publicData.rules ? (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="ListingPage.rulesTitle" />
      </h2>
      <p className={css.rules}>{publicData.rules}</p>
      <PropertyGroup
        id="ListingPage.ruleOptions"
        options={RULES_OPTIONS}
        selectedOptions={publicData.rulesOptions}
        twoColumns={true}
        hideUnselected={true}
      />
      {moveInOutSection}
      {tenantsAllowedSection}
      {petPolicySection}
    </div>
  ) : null;
};

SectionRulesMaybe.defaultProps = { className: null, rootClassName: null };

SectionRulesMaybe.propTypes = {
  className: string,
  rootClassName: string,
  publicData: shape({
    rules: string,
  }),
};

export default SectionRulesMaybe;
