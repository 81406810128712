import React from 'react';
import { shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const SectionInteriorFeatures = props => {
  const { className, rootClassName, publicData = {}} = props;
  const classes = classNames(rootClassName || css.interiorFeaturesRoot, className);

  const { footage = 0, numberOfBeds = 0, numberOfBaths = 0 } = publicData;

  return footage || numberOfBeds || numberOfBaths ? (
    <div className={classes}>
      <h2 className={css.interiorFeaturesTitle}>
        <FormattedMessage id="ListingPage.interiorFeaturesTitle" />
      </h2>
      <div className={css.interiorFeaturesRow}>
        { numberOfBeds ? (
          <div className={css.bedroomsCell}>
            <FormattedMessage id="ListingPage.numberOfBedrooms" />
            <div className={css.bedrooms}>{numberOfBeds}</div>
          </div>
        ) : null }
        { numberOfBaths ? (
          <div className={css.bathroomsCell}>
            <FormattedMessage id="ListingPage.numberOfBathrooms" />
            <div className={css.bathrooms}>{numberOfBaths}</div>
          </div>
        ) : null }
        { footage ? (
          <div className={css.footageCell}>
            <FormattedMessage id="ListingPage.footage" />
            <div className={css.footage}>{footage}</div>
          </div>
        ) : null }
      </div>
    </div>
  ) : null;
};

SectionInteriorFeatures.defaultProps = { className: null, rootClassName: null };

SectionInteriorFeatures.propTypes = {
  className: string,
  rootClassName: string,
  publicData: shape({
    rules: string,
  }),
};

export default SectionInteriorFeatures;
