import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, Modal } from '../../components';
import { ReportListingForm } from '../../forms';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import config from '../../config';

import css from './ListingPage.module.css';

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    title,
    richTitle,
    authorDisplayName,
    category,
    type,
    tenantSpaceType,
    hostLink,
    showContactUser,
    publicData,
    onContactUser,
    onManageDisableScrolling,
    onReportListing,
    isReportListingModalOpen,
    onCloseReportListingModal,
    onSubmitListingReport,
    sendListingReportError,
    sendListingReportInProgress,
  } = props;

  const { timeUnit = ''} = publicData;
  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = timeUnit === 'night'
    ? 'ListingPage.perNight' : 'ListingPage.perMonth';

  return (
    <div className={css.sectionHeading}>
      <div className={css.desktopPriceContainer}>
        <div className={css.desktopPriceValue} title={priceTitle}>
          {formattedPrice}
        </div>
        <div className={css.desktopPerUnit}>
          <FormattedMessage id={unitTranslationKey} />
        </div>
      </div>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {category}
          {type}
          {tenantSpaceType}<br/>
          <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} />
          {showContactUser ? (
            <span className={css.contactWrapper}>
              <span className={css.separator}>•</span>
              <InlineTextButton
                rootClassName={css.contactLink}
                onClick={onContactUser}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
        </div>
        <InlineTextButton rootClassName={css.reportListingLink} onClick={onReportListing}>
          <FormattedMessage id="ListingPage.reportListing" />
        </InlineTextButton>
      </div>
      <Modal
        id="ListingPage.reportListing"
        contentClassName={css.reportListingModalContent}
        isOpen={isReportListingModalOpen}
        onClose={onCloseReportListingModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ReportListingForm
          className={css.reportListingForm}
          submitButtonWrapperClassName={css.reportListingSubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendListingReportError={sendListingReportError}
          onSubmit={onSubmitListingReport}
          inProgress={sendListingReportInProgress}
        />
      </Modal>
    </div>
  );
};

export default SectionHeading;
