import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import css from './ListingPage.module.css';

const SectionCancellationPolicy = props => {
  const { cancellationPolicy, publicData, intl } = props;
  const { timeUnit, checkInTime, checkOutTime } = publicData;

  const policyExplanations = {
    policy48Hours: timeUnit === 'night' ?
      {
        twoWeeksBeforeLabel: "14 Days Prior",
        twoWeeksBeforeTitle: "48 Hours After Booking",
        twoWeeksBeforeDescription: "For a full refund of accommodation fees, cancellation must be made within 48 hours of booking and at least 14 full days prior to listing’s local check-in time (or 3:00 PM if not specified) on the day of check-in.",
        moveInLabel: "Move In",
        moveInDate: "Fri, Jan 11",
        moveInTime: checkInTime ? checkInTime : "03:00 PM",
        moveInDescription: "For a full refund of accommodation fees, cancellation must be made 14 full days prior to listing’s local check in time (or 3:00 PM if not specified) on the day of check in, otherwise no refund. For example, if check-in is on Friday, cancel by Friday two weeks before check in time.",
        moveOutLabel: "Move Out",
        moveOutDate: "Mon, Jan 14",
        moveOutTime: checkOutTime ? checkOutTime : "11:00 AM",
        moveOutDescription: "If the guest cancels less than 14 days in advance, no refund is issued.",
      } : {
        moveInLabel: "Move In",
        moveInDate: "Fri, Jan 11",
        moveInTime: checkInTime ? checkInTime : "03:00 PM",
        moveInDescription: "If the guest books a long term reservation and decides to cancel the reservation later than 48 hours after the booking or less than 14 days before the local check-in time, the first month of the reservation is paid to the host in full and not refunded to the guest.",
        moveOutLabel: "Move Out",
        moveOutDate: "Mon, Jan 14",
        moveOutTime: checkOutTime ? checkOutTime : "11:00 AM",
        moveOutDescription: "If the guest books a reservation and decides to cancel the reservation during their stay, the guest must use the online alteration tool in order to agree to a new checkout date. Regardless of the checkout date chosen, the guest is required to pay the host for the 30 days following the cancellation date, or up to the end date of the guest’s original reservation if the remaining portion of the original reservation is less than 30 days.",
      }
  };

  return cancellationPolicy && policyExplanations[ cancellationPolicy ] && timeUnit === 'night' ? (
    <>
      <div className={css.cancellationPolicyBlockTitle}>Cancellation policy ( by example )</div>
      <NamedLink name="CancellationPolicyPage" to={{ search: 'units=nightly', }}>
        <FormattedMessage id="ListingPage.readMoreCancellation" />
      </NamedLink>
      <div className={css.cancellationPolicyBlock}>
        <div className={css.cancellationBeforeTwoWeeksPolicyMoveInBlock}>
          <div className={css.cancellationPolicyMoveInLabel}>
            { policyExplanations[ cancellationPolicy ].twoWeeksBeforeLabel }
          </div>
          <div className={css.cancellationPolicyBeforeTwoWeeksColorHeader}>&nbsp;</div>
          <div className={css.cancellationPolicyMoveInDate}>
            { policyExplanations[ cancellationPolicy ].twoWeeksBeforeTitle }
          </div>
          <div className={css.cancellationPolicyMoveInTime}>
            &nbsp;
          </div>
          <div className={css.cancellationPolicyMoveInDescription}>
            { policyExplanations[ cancellationPolicy ].twoWeeksBeforeDescription }
          </div>
        </div>
        <div className={css.cancellationPolicyNightlyMoveOutBlock}>
          <div className={css.cancellationPolicyMoveInLabel}>
            { policyExplanations[ cancellationPolicy ].moveInLabel }
          </div>
          <div className={css.cancellationPolicyMoveInColorHeader}>&nbsp;</div>
          <div className={css.cancellationPolicyMoveInDate}>
            { policyExplanations[ cancellationPolicy ].moveInDate }
          </div>
          <div className={css.cancellationPolicyMoveInTime}>
            { policyExplanations[ cancellationPolicy ].moveInTime }
          </div>
          <div className={css.cancellationPolicyMoveInDescription}>
            { policyExplanations[ cancellationPolicy ].moveInDescription }
          </div>
        </div>
        <div className={css.cancellationPolicyNightlyMoveOutBlock}>
          <div className={css.cancellationPolicyMoveOutLabel}>
            { policyExplanations[ cancellationPolicy ].moveOutLabel }
          </div>
          <div className={css.cancellationPolicyMoveOutColorHeader}>&nbsp;</div>
          <div className={css.cancellationPolicyMoveOutDate}>
            { policyExplanations[ cancellationPolicy ].moveOutDate }
          </div>
          <div className={css.cancellationPolicyMoveOutTime}>
            { policyExplanations[ cancellationPolicy ].moveOutTime }
          </div>
          <div className={css.cancellationPolicyMoveOutDescription}>
            { policyExplanations[ cancellationPolicy ].moveOutDescription }
          </div>
        </div>
      </div>
    </>
  ) : cancellationPolicy && policyExplanations[ cancellationPolicy ] && timeUnit === 'month' ? (
    <>
      <div className={css.cancellationPolicyBlockTitle}>Cancellation policy ( by example )</div>
      <NamedLink name="CancellationPolicyPage" to={{ search: 'units=monthly', }}>
        <FormattedMessage id="ListingPage.readMoreCancellation" />
      </NamedLink>
      <div className={css.cancellationPolicyBlock}>
        <div className={css.cancellationPolicyMonthlyMoveOutBlock}>
          <div className={css.cancellationPolicyMoveInLabel}>
            { policyExplanations[ cancellationPolicy ].moveInLabel }
          </div>
          <div className={css.cancellationPolicyMoveInColorHeader}>&nbsp;</div>
          <div className={css.cancellationPolicyMoveInDate}>
            { policyExplanations[ cancellationPolicy ].moveInDate }
          </div>
          <div className={css.cancellationPolicyMoveInTime}>
            { policyExplanations[ cancellationPolicy ].moveInTime }
          </div>
          <div className={css.cancellationPolicyMoveInDescription}>
            { policyExplanations[ cancellationPolicy ].moveInDescription }
          </div>
        </div>
        <div className={css.cancellationPolicyMonthlyMoveOutBlock}>
          <div className={css.cancellationPolicyMoveOutLabel}>
            { policyExplanations[ cancellationPolicy ].moveOutLabel }
          </div>
          <div className={css.cancellationPolicyMoveOutColorHeader}>&nbsp;</div>
          <div className={css.cancellationPolicyMoveOutDate}>
            { policyExplanations[ cancellationPolicy ].moveOutDate }
          </div>
          <div className={css.cancellationPolicyMoveOutTime}>
            { policyExplanations[ cancellationPolicy ].moveOutTime }
          </div>
          <div className={css.cancellationPolicyMoveOutDescription}>
            { policyExplanations[ cancellationPolicy ].moveOutDescription }
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default SectionCancellationPolicy;
