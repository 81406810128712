import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { InlineTextButton } from '../../components';

const { Money } = sdkTypes;

import css from './ListingPage.module.css';

import bathImage from './images/bath.png';
import bedImage from './images/bed.png';
import catImage from './images/cat.png';
import depositImage from './images/deposit.png';
import dogImage from './images/dog.png';
import sqrImage from './images/sqr.png';

const SectionOverview = props => {
  const {
    publicData,
    intl,
  } = props;

  const { numberOfBaths = 0, numberOfBeds = 0, timeUnit = ''} = publicData;
  const catsAllowed =
    Array.isArray( publicData.catsAllowed ) && publicData.catsAllowed.length === 1 &&
      publicData.catsAllowed[ 0 ] === 'yes';
  const dogsAllowed =
    Array.isArray( publicData.dogsAllowed ) && publicData.dogsAllowed.length === 1 &&
      publicData.dogsAllowed[ 0 ] === 'yes';
  const { securityDeposit = 0 } = publicData;
  const securityDepositAmount = new Money( securityDeposit, 'USD');
  const formattedDeposit = formatMoney( intl, securityDepositAmount );


  const bedroomsCell = (
    <div className={css.overviewCell}>
      <img src={bedImage} alt={'Number Of Bedrooms'} className={css.overviewImage} />
      <div className={css.overviewText}>Bedroom(s): {numberOfBeds}</div>
    </div>
  );

  const bathroomsCell = (
    <div className={css.overviewCell}>
      <img src={bedImage} alt={'Number Of Bathrooms'} className={css.overviewImage} />
      <div className={css.overviewText}>Bathroom(s): {numberOfBaths}</div>
    </div>
  );

  const depositCell = timeUnit === 'month' && securityDeposit ? (
    <div className={css.overviewCell}>
      <img src={depositImage} alt={'Security Deposit'} className={css.overviewImage} />
      <div className={css.overviewText}>{formattedDeposit}</div>
    </div>
  ) : null;

  const catsAllowedCell = catsAllowed ? (
    <div className={css.overviewCell}>
      <img src={catImage} alt={'Cats Allowed'} className={css.overviewImage} />
      <div className={css.overviewText}>Cats Allowed</div>
    </div>
  ) : null;

  const dogsAllowedCell = dogsAllowed ? (
    <div className={css.overviewCell}>
      <img src={dogImage} alt={'Dogs Allowed'} className={css.overviewImage} />
      <div className={css.overviewText}>Dogs Allowed</div>
    </div>
  ) : null;

  const overviewFirstRow = bedroomsCell || bathroomsCell || depositCell ? (
    <div className={css.overviewFirstRow}>
      {bedroomsCell}
      {bathroomsCell}
      {depositCell}
    </div>
  ) : null;

  const overviewSecondRow = catsAllowedCell || dogsAllowedCell ? (
    <div className={css.overviewSecondRow}>
      {catsAllowedCell}
      {dogsAllowedCell}
    </div>
  ) : null;

  return (
    <div className={css.sectionOverview}>
      {overviewFirstRow}
      {overviewSecondRow}
    </div>
  );
};

export default SectionOverview;
